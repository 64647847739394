@tailwind base;
@tailwind components;
@tailwind utilities;


html,body,#root{
  height: 100%;
  padding: 0;
  margin: 0;
}

.login_bg {
  background-image: linear-gradient(to bottom left,#b3d0ff,#ffc5eb);
}